/*
  import { notifyError } from '@/services/notify'

	import { getBoardPackage } from '@/services/BoardMeetings/BoardPackage/Get';

  await getBoardPackage ({
    boardPackageID
  }).then (({ successful, message, boardPackage }) => {
    if (!successful) {
      notifyError (message);
      return;
    }
    
  })
  
*/

import kms from '@/services/kms'
import { notifyError } from '@/services/notify'

export async function getBoardPackage({ boardPackageID }) {
  try {
    const result = await kms.get(`/BoardMeetings/BoardPackage/${boardPackageID}`)

    return {
      successful: true,
      message: `The board meeting details were retrieved successfully.`,
      boardPackage: result
    }
  } catch (exception) {
    console.error(exception)
  }

  const message = `Board meeting ${boardPackageID} could not be retrieved.  It might have been deleted or you might not have permission to open it.`
  notifyError(message)

  return {
    successful: false,
    message,
    boardPackage: null
  }
}
