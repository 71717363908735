import _get from 'lodash/get'
import { getBoardPackage } from '@/services/BoardMeetings/BoardPackage/Get'

export const methods = {
  _get,
  async refresh() {
    const id = parseInt(_get(this, ['$route', 'params', 'id'], ''))

    await getBoardPackage({
      boardPackageID: id
    }).then(({ successful, message }) => {
      if (!successful) {
        this.status = 'not found'
        this.statusMessage = message
        throw new Error(message)
      }
    })

    this.status = 'found'
  },
  back() {
    this.$router
      .push({
        name: 'boardMeetings.list'
      })
      .catch(() => {})
  }
}
