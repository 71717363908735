<template>
  <PageContentLayoutOnly role="region">
    <div v-if="status === 'found'">
      <div class="level" :style="{ display: 'flex', justifyContent: 'space-around' }">
        <div class="level-left level-item title">{{ $t('boardMeetingsDetails.title') }}</div>
        <BackButton label="Back to Meetings List" :click="back" />
      </div>

      <LinksMenu
        aria-label="Board Meeting Details Tabs"
        :tabs="tabs"
        prefix="boardMeetingsDetails."
        :params="{
          id: _get($route, 'params.id', '')
        }"
        :style="{
          paddingLeft: 0
        }"
      >
        <section
          role="region"
          :style="{ overflowX: 'auto', flexGrow: 2, padding: '20px' }"
          class="card"
        >
          <SlideTransition :name="transitionName">
            <keep-alive>
              <router-view :key="$route.name" />
            </keep-alive>
          </SlideTransition>
        </section>
      </LinksMenu>
    </div>

    <NotFound v-if="status === 'not found'" :note="statusMessage" />
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import SlideTransition from '@/components/transitions/Slide.vue'
import LinksMenu from '@/components/menus/Links'
import BackButton from '@/components/buttons/Back'
import NotFound from '@/components/NotFound'
import { data } from './parts/data'
import { methods } from './parts/methods'

export default {
  components: {
    PageContentLayoutOnly,
    LinksMenu,
    SlideTransition,
    BackButton,
    NotFound
  },

  data,
  methods,

  beforeMount() {
    this.refresh()
  },

  i18n: {
    messages: {
      en: {
        boardMeetingsDetails: {
          title: 'Board Meeting Details'
        }
      }
    }
  }
}
</script>
