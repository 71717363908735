<template>
  <div
    :style="{
      padding: '50px'
    }"
  >
    <p
      :style="{
        textAlign: 'center',
        fontSize: '1.2rem'
      }"
    >
      {{ note }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    note: {
      type: String,
      default: 'Not Found'
    }
  }
}
</script>
