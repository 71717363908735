<template>
  <div :style="{ display: 'flex', alignItems: 'center' }">
    <svg
      width="30"
      height="30"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M15 19l-7-7 7-7"
      ></path>
    </svg>
    <b-button :label="label" @click.prevent="click" />
  </div>
</template>

<script>
/*
	import BackButton from '@/components/buttons/Back'
*/
export default {
  props: {
    click: Function,
    breakpoint: null,
    label: {
      type: String,
      default: ''
    }
  }
}
</script>
