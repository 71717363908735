export function data() {
  return {
    // "fetching", "not-found", "found"
    status: 'fetching',
    statusMessage: '',

    tabs: [
      { name: 'boardMeetingsDetail', label: 'Detail' },
      { name: 'boardMeetingsAgenda', label: 'Agenda' },
      { name: 'boardMeetingsBoardPackageLayout', label: 'Board Package Layout' },
      { name: 'boardMeetingsMinutes', label: 'Minutes' }
    ],
    transitionName: 'slide-down'
  }
}
